import Vue from 'vue';
import pathify from './pathify';
import Vuex, { Store } from 'vuex';
import auth, { IAuthState } from './auth';
import study, { IStudyState } from './study';
import xRay, { IXRayState } from './x-ray';
import sensor, { ISensorState } from './sensor';
import patient, { IPatientState } from './patient';
import owner, { IOwnerState } from './owner';
import generator, { IGeneratorState } from './generator';
import clinic, { IClinicState } from './clinic';
import user, { IUserState } from './user';
import breed, { IBreedState } from './breed';
import viewer, { IViewerState } from './viewer';
import caseViewer, { ICaseViewerState } from './caseViewer';
import dicomServer, { IDicomServerState } from './dicomServer';
import email, { IEmailState } from './email';
import radcase, { IRadCaseState } from './case';
import report, { IReportState } from './report/index';
import permissionProfile, { IPermissionProfileState } from './permissionProfile/index';
import caseReport from './caseReport/index';

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthState;
  breed: IBreedState;
  email: IEmailState;
  study: IStudyState;
  patient: IPatientState;
  owner: IOwnerState;
  xRay: IXRayState;
  sensor: ISensorState;
  generator: IGeneratorState;
  clinic: IClinicState;
  user: IUserState;
  viewer: IViewerState;
  caseViewer: ICaseViewerState; // Make this optional
  dicomServer: IDicomServerState;
  report: IReportState;
  permissionProfile: IPermissionProfileState;
  radcase: IRadCaseState;
  caseReport: IReportState;
}

export const store = new Store<IRootState>({
  modules: {
    sensor,
    auth,
    breed,
    clinic,
    dicomServer,
    email,
    generator,
    patient,
    study,
    owner,
    xRay,
    user,
    report,
    permissionProfile,
    radcase
  },
  plugins: [pathify.plugin]
});

export function initializeStore (appStore: Store<IRootState>): void {
  // Unregister both viewers initially to avoid conflicts

  if (appStore.hasModule('viewer')) {
    appStore.unregisterModule('viewer');
  }
  if (appStore.hasModule('caseViewer')) {
    appStore.unregisterModule('caseViewer');
  }
  if (appStore.hasModule('caseReport')) {
    appStore.unregisterModule('caseReport');
  }

  // Register the appropriate viewer module based on the user's profile permissions
  const profile = store.state.permissionProfile.profile;
  if (profile?.radiologistCase) {
    appStore.registerModule('caseViewer', caseViewer);
    appStore.registerModule('caseReport', caseReport);
  } else {
    appStore.registerModule('viewer', viewer);
  }
}

initializeStore(store);

export default store;
