























import Vue from 'vue';
import { get } from 'vuex-pathify';
import { IDisplay } from '@/state/caseViewer/images/images-types';
import { Prop } from 'vue/types/options';
import { ITool } from './toolLists';
import { ClinicType } from '@/api/Models';
import { isAppLocal } from '@/helpers/config';

export default Vue.extend({
  props: {
    tool: {
      type: Object as Prop<ITool>,
      required: true
    }
  },
  computed: {
    activeToolId: get<string>('caseViewer/tools/activeToolId'),
    clinicType: get<ClinicType>('clinic/clinic@clinicType'),
    displayGrid: get<IDisplay[]>('caseViewer/images/displayGrid'),
    isSingleImage: get<boolean>('caseViewer/images/isSingleImage'),
    isActive (): boolean {
      return this.activeToolId === this.tool.id;
    },
    showTool (): boolean {
      return !this.tool.hidden && (this.tool.clinicType === undefined || this.tool.clinicType === this.clinicType);
    },
    disabled (): boolean {
      return this.tool.isSingleImageOnly ? !this.isSingleImage : false;
    },
    isFontAwesomeIcon (): boolean {
      return typeof this.tool.icon !== 'string';
    },
    iconSize (): string {
      return window.innerWidth > 1800 || isAppLocal() ? 'lg' : 'sm';
    }
  },
  methods: {
    onClick (): void {
      this.tool.action();
    }
  }
});
