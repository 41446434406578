























































import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import Table from './components/Table.vue';
import { get } from 'vuex-pathify';
import { IRadCaseStat } from '@/api/Models';

export default Vue.extend({
  components: {
    PageTemplate,
    Table
  },
  data () {
    return {
      StudyDateFrom: '',
      StudyDateTo: '',
      selectedStatus: 1,
      availableCount: 0,
      assignedCount: 0,
      waitingsCount: 0,
      finalizedCount: 0,
      cancelledCount: 0
    };
  },
  computed: {
    cases: get('radcase/radcases')
  },
  watch: {
    $route () {
      this.fetchCasesStats();
      this.filterCasesbyStatus(this.selectedStatus);
    },
    StudyDateFrom (value) {
      const hiddenInput = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    },
    StudyDateTo (value) {
      const hiddenInput = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    }
  },
  async mounted () {
    await this.fetchCasesStats();
    this.filterCasesbyStatus(this.selectedStatus);
  },
  methods: {
    filterCasesbyStatus (status: number): void {
      this.selectedStatus = status;
      this.$store.dispatch('radcase/fetchCasesByStatus', status);
    },
    async fetchCasesStats () {
      try {
        const response: IRadCaseStat[] = await this.$store.dispatch('radcase/fetchCasesStats');
        if (response && Array.isArray(response)) {
          response.forEach(stat => {
            switch (stat.status) {
              case 1:
                this.availableCount = stat.count;
                break;
              case 2:
                this.assignedCount = stat.count;
                break;
              case 3:
                this.waitingsCount = stat.count;
                break;
              case 4:
                this.finalizedCount = stat.count;
                break;
              case 5:
                this.cancelledCount = stat.count;
                break;
              default:
                break;
            }
          });
        }
      } catch (error) {
        console.error('Error fetching case stats:', error);
      }
    }
  }
});
