




































































































































import Vue, { PropType } from 'vue';

import { Prop } from 'vue/types/options';
import { dispatch } from 'vuex-pathify';
import { IReportPage } from '@/state/report';
import eventBus, { ModalEventNames } from '@/eventBus';
import { IRadCaseReportModel } from '../../../api/Models';

export default Vue.extend({
  components: {},
  props: {
    page: {
      type: Object as Prop<IReportPage>,
      required: true
    },
    pageIndex: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true // Pass the total number of pages as a prop
    },
    isThumbnail: {
      type: Boolean,
      default: false
    },
    radCase: {
      type: Object as Prop<IRadCaseReportModel>,
      required: false
    },
    files: {
      type: Array as PropType<{ url: string; mimeType: string }[]>,
      default: () => []
    },
    age: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editorHeightLimit: undefined as undefined | number,
      selectedPlaceholderIndex: undefined as number | undefined
    };
  },
  computed: {
    isFirstPage (): boolean {
      return this.pageIndex === 0;
    },
    isLastPage (): boolean {
      return this.pageIndex === this.totalPages - 1; // Check if the current page is the last page
    },
    getImageClass (): string {
      // if (this.files.length === 1) return 'images-1';
      // if (this.files.length === 2) return 'images-2';
      return 'images-1';
    }
  },
  mounted () {
    this.initializeImagePickerListener();
  },
  beforeDestroy () {
    eventBus.$off(ModalEventNames.imageSelected);
  },
  methods: {
    initializeImagePickerListener (): void {
      eventBus.$on(ModalEventNames.imageSelected, async (imageIds: number[]) => {
        const partialReportImage = { id: imageIds[0], index: this.selectedPlaceholderIndex };
        await dispatch('report/addImage', { image: partialReportImage, pageIndex: this.pageIndex });

        eventBus.$emit(ModalEventNames.closeModal);
      });

      eventBus.$on(ModalEventNames.closeModal, () => {
        this.selectedPlaceholderIndex = undefined;
      });
    }
  }
});
