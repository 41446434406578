




















































import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import { IRadCaseModel, IBreedModel, IUserModel, IRadCaseResponse, IPermissionProfileModel } from '@/api/Models';
import TableList from './TableList.vue';
import { dateSort, formatDateTime } from '@/helpers/date';
import AssignCaseButton from '@/components/AssignCaseButton.vue';

type TableComponentInstance = Vue & {
  selectedCase: IRadCaseModel | undefined;
  user: IUserModel | undefined;
  cases: IRadCaseModel[];
  breeds: IBreedModel[];
  transformedCases: unknown[];
  onTakeCase: () => Promise<void>;
  onCancelClick: () => Promise<void>;
  onEditClick: () => Promise<void>;
  handleMenuItemClick: (option: string) => void;
};
export default Vue.extend({
  components: {
    TableList,
    AssignCaseButton
  },
  props: {
    selectedStatus: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      columns: [
        { label: 'Case#', field: 'caseId' },
        { label: 'Patient', field: 'patientName' },
        { label: 'ClinicName', field: 'clinicName' },
        { label: 'ReferringVeterinarianName', field: 'referringVeterinarianName' },
        { label: 'Breed', field: 'breed' },
        { label: 'Modality', field: 'modality' },
        { label: 'Priority', field: 'priority' },
        { label: 'SubmitOn', field: 'submitOn', formatFn: formatDateTime, sortFn: dateSort }
      ],
      selectedCase: undefined as IRadCaseResponse | undefined
    };
  },
  computed: {
    cases: get<IRadCaseResponse[]>('radcase/radcases'),
    breeds: get<IBreedModel[]>('breed/breeds'),
    user: get<IUserModel | undefined>('user/user'),
    permissionProfile: get<IPermissionProfileModel>('permissionProfile/profile'),
    isRadiologist (): boolean {
      return this.permissionProfile.radiologistCase;
    },
    transformedCases () {
      return this.cases.map(caseItem => {
        const id = caseItem.id ?? 0; // Fallback to 0 if id is undefined
        const breed = this.breeds.find(b => b.id === caseItem.breedId)?.name ?? 'Unknown Breed';

        return {
          ...caseItem,
          caseId: `VWR-CASE-${id + 1000}`,
          breed
        };
      });
    },
    selectedCaseId (this: TableComponentInstance) {
      return this.selectedCase ? this.selectedCase.id : 0;
    },
    assignButtonText (): string {
      return this.selectedStatus === 1 ? 'Assign Case' : 'Re-assign Case';
    }
  },
  methods: {
    async onTakeCase (this: TableComponentInstance) {
      const formData = new FormData();
      formData.append('caseId', JSON.stringify(this.selectedCase?.id));
      formData.append('ragiologistId', JSON.stringify(this.user?.id));
      try {
        await dispatch('radcase/takeCase', formData);
        this.$router.push({ name: 'Editcases', params: { caseId: String(this.selectedCase?.id) } });
      } catch (e) {
        console.log(e);
      }
    },
    async onCancelClick (this: TableComponentInstance) {
      const formData = new FormData();
      formData.append('caseId', JSON.stringify(this.selectedCase?.id));
      try {
        await dispatch('radcase/cancelCase', formData);
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
    onEditClick (this: TableComponentInstance) {
      this.$router.push({ name: 'Editcases', params: { caseId: String(this.selectedCase?.id) } });
    },
    onAssignClick (this: TableComponentInstance) {
      this.$router.push({ name: 'Editcases', params: { caseId: String(this.selectedCase?.id) } });
    },
    handleMenuItemClick (this: TableComponentInstance, option: string) {
      console.log('AMMAR>>handleMenuItemClick', option);
    }
  }
});
