/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  importInternal,
  getToolState,
  clearToolState,
  external,
  getModule,
  Handle,
  NewMeasurementEventData,
  Measurement,
  removeToolState,
  Handles
} from 'cornerstone-tools';
import { dispatch } from 'vuex-pathify';
import { getColor } from './utils/getColor';
import { attachEventToOverlay, attachToOverlay, removeFromOverlay } from './utils/toolOverlay';

const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');

// Drawing
const getNewContext = importInternal('drawing/getNewContext');
const draw = importInternal('drawing/draw');
const setShadow = importInternal('drawing/setShadow');
const drawRect = importInternal('drawing/drawRect');
const drawHandles = importInternal('drawing/drawHandles');

// Util
const rectangleRoiCursor = importInternal('tools/cursors').rectangleRoiCursor;
const getLogger = importInternal('util/getLogger');
const throttle = importInternal('util/throttle');

const logger = getLogger('tools:annotation:RectangleRoiTool');

/**
 * @public
 * @class RectangleRoiTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class CropTool extends BaseAnnotationTool {
  constructor (props = {}) {
    const defaultProps = {
      name: 'Crop',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false
      },
      svgCursor: rectangleRoiCursor
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement (eventData: NewMeasurementEventData): Measurement | undefined {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );
      return;
    }

    // Prevent more than 1 measurement being drawn
    const toolData = getToolState(eventData.element, this.name);
    if (toolData && toolData.data.length >= 1) {
      return;
    }

    const defaultHandle: Handle = {
      x: eventData.currentPoints.image.x,
      y: eventData.currentPoints.image.y,
      highlight: true,
      active: false
    };

    const element = eventData.element;

    const endCoords = defaultHandle;
    // attach buttons in the bottom right side of the cropping square
    attachToOverlay('Accept', 'accept-btn', element, endCoords.x as number - 45, endCoords.y as number + 20);
    attachToOverlay('Reject', 'reject-btn', element, endCoords.x as number - 105, endCoords.y as number + 20);

    attachEventToOverlay('accept-btn', element, 'click', async () => {
    
      if (document.body.classList.contains('viewer-active')) {
        const measurementData = getToolState(element, this.name).data[0];
        const cropArea: Handles = {
          start: getCornerCoords(measurementData.handles, 'top-left'),
          end: getCornerCoords(measurementData.handles, 'bottom-right')
        };

        removeToolState(element, this.name, measurementData);
        await dispatch('viewer/tools/cropViewport', cropArea);
        await dispatch('viewer/images/saveViewport');
      }
    });
    attachEventToOverlay('reject-btn', element, 'click', () => {
      if (document.body.classList.contains('viewer-active')) {
        const measurementData = getToolState(element, this.name).data[0];
        removeToolState(element, this.name, measurementData);
        triggerRender(element, this.name);
      }
    });

    return {
      toolType: 'Crop',
      active: false,
      visible: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: defaultHandle,
        end: {
          ...defaultHandle,
          active: true
        },
        initialRotation: eventData.viewport.rotation
      }
    };
  }

  pointNearTool (element: HTMLElement, data: any, coords: any, interactionType: string): boolean {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      logger.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const distance = interactionType === 'mouse' ? 15 : 25;
    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );
    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y)
    };

    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
      rect,
      coords
    );

    return distanceToPoint < distance;
  }

  // cornerstone-tools shows a warning if we don't implement this method
  updateCachedStats (): void {
    return undefined;
  }

  renderToolData (evt: any): void {
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { element } = eventData;
    const lineDash = getModule('globalConfiguration').configuration.lineDash;
    const {
      handleRadius,
      hideHandlesIfMoving,
      renderDashed
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);

    if (toolData.data.length !== 0) {
      draw(context, (canvasContext: CanvasRenderingContext2D) => {
        // If we have tool data for this element - iterate over each set and draw it
        for (let i = 0; i < toolData.data.length; i++) {
          const data = toolData.data[i];

          if (data.visible === false) {
            continue;
          }

          // Configure
          const color = getColor(data);
          const handleOptions = {
            color,
            handleRadius,
            drawHandlesIfActive: false,
            hideHandlesIfMoving
          };

          setShadow(canvasContext, this.configuration);

          const rectOptions: any = { color };

          if (renderDashed) {
            rectOptions.lineDash = lineDash;
          }

          // Draw
          drawRect(
            canvasContext,
            element,
            data.handles.start,
            data.handles.end,
            rectOptions,
            'pixel',
            data.handles.initialRotation
          );

          if (this.configuration.drawHandles) {
            drawHandles(canvasContext, eventData, data.handles, handleOptions);
          }

          // attach buttons in the bottom right side of the cropping square
          const endCoords = external.cornerstone.pixelToCanvas(element, getCornerCoords(data.handles, 'bottom-right'));
          attachToOverlay('Accept', 'accept-btn', element, endCoords.x - 45, endCoords.y + 20);
          attachToOverlay('Reject', 'reject-btn', element, endCoords.x - 105, endCoords.y + 20);
        }
      });
    } else {
      removeFromOverlay('accept-btn', element);
      removeFromOverlay('reject-btn', element);
    }
  }

  passiveCallback (element: HTMLElement): void {
    clearToolState(element, this.name);
    triggerRender(element, this.name);

    removeFromOverlay('accept-btn', element);
    removeFromOverlay('reject-btn', element);
  }
}

// triggers viewport re-render
function triggerRender (element: HTMLElement, name: string): void {
  const toolData = getToolState(element, name);
  if (!toolData) {
    return;
  }
  external.cornerstone.updateImage(element);
}

function getCornerCoords (handles: Handles, corner: 'top-left' | 'bottom-right'): { x: number; y: number } {
  const { start, end } = handles;

  if (start.x > end.x && start.y > end.y) {
    return corner === 'bottom-right' ? start : end;
  } else if (end.x > start.x && end.y > start.y) {
    return corner === 'bottom-right' ? end : start;
  } else if (start.x > end.x && end.y > start.y) {
    return corner === 'bottom-right' ? { x: start.x, y: end.y } : { x: end.x, y: start.y };
  }
  return corner === 'bottom-right' ? { x: end.x, y: start.y } : { x: start.x, y: end.y };
}
