

































































































































































































import Vue from 'vue';
import { mapActions } from 'vuex';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import { IRadCaseModel, IBreedModel, IClinicModel, IFileIdStatus, IPermissionProfileModel } from '@/api/Models';
import { get, dispatch } from 'vuex-pathify';
import { getAge } from '@/state/viewer/tools/helpers';
import RequestMoreInfoButton from '@/components/RequestMoreInfoButton.vue';
import ViewCaseImagesButton from '@/components/ViewCaseImagesButton.vue';
import FileUpload from '@/components/FileUpload.vue';
import { navigate, NavigationMethods } from '@/router/helpers';
type FieldKey = 'findings' | 'interpretation' | 'internalMedicineRecommandations';

interface IRadCaseTransformedModel {
  radCase: IRadCaseModel;
  breedName: string;
  clinicName: string;
  email: string;
  age: string;
}
interface IFilePreview {
  name: string;
  src: Blob | string | ArrayBuffer | null; // Adjusted to include Blob
  file: File;
}
export default Vue.extend({ components: {
  PageTemplate,
  RequestMoreInfoButton,
  ViewCaseImagesButton,
  FileUpload
},
data () {
  return {
    StudyDateFrom: '',
    StudyDateTo: '',
    caseData: null as IRadCaseTransformedModel | null,
    files: [] as { id: number; url: string; mimeType: string; name: string }[],
    radioghraphs: [] as { id: number; url: string; mimeType: string }[],
    fileIdStatues: [] as IFileIdStatus[],
    caseId: '',
    filePreviews: [] as IFilePreview[],

    form: {
      findings: '',
      interpretation: '',
      internalMedicineRecommandations: '',
      signature: ''
    },
    maxLength: {
      findings: 800,
      interpretation: 300,
      internalMedicineRecommandations: 300
    },
    remainingCharacters: {
      findings: 800,
      interpretation: 300,
      internalMedicineRecommandations: 300
    }
  };
},
computed: {
  breeds: get<IBreedModel[]>('breed/breeds'),
  clinic: get<IClinicModel>('clinic/clinic'),
  permissionProfile: get<IPermissionProfileModel>('permissionProfile/profile'),
  isRadiologist (): boolean {
    return this.permissionProfile.radiologistCase;
  }
},
 
watch: {
  StudyDateFrom (value) {
    const hiddenInput = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement;
    if (hiddenInput) {
      hiddenInput.value = value;
    }
  },
  StudyDateTo (value) {
    const hiddenInput = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement;
    if (hiddenInput) {
      hiddenInput.value = value;
    }
  }
},
async created () {
  const caseId = this.$route.params.caseId;
  if (caseId) {
    this.caseId = caseId;
    await this.fetchCaseDetails(caseId);
    await this.fetchStudy(this.caseData?.radCase.studyId);
    await this.fetchPatientById(this.caseData?.radCase.patientId);
    await this.fetchOwnerById(this.caseData?.radCase.ownerId);
    for (const file of this.fileIdStatues) {
      const fileData = await this.fetchFileById(file?.id ?? 0);
      if (fileData) {
        if (file.isFromReceiver) {
          this.radioghraphs.push({
            id: file?.id ?? 0,
            url: fileData.url,
            mimeType: fileData.mimeType
          });
        } else {
          this.files.push({
            id: file?.id ?? 0,
            url: fileData.url,
            mimeType: fileData.mimeType,
            name: file.fileName
          });
        }
      }
    }
  }
},
mounted () {
  const datePickerInputs = document.querySelectorAll('.vdp-datepicker div input') as NodeListOf<HTMLInputElement>;
  datePickerInputs.forEach(datePickerInput => {
    datePickerInput.addEventListener('blur', () => {
      datePickerInput.style.backgroundColor = 'transparent';
    });
  });
},

methods: {
  ...mapActions({
    fetchStudy: 'study/fetchStudyById',
    fetchPatientById: 'patient/fetchPatientById',
    fetchOwnerById: 'owner/fetchOwnerById'
  }),
   updateRemainingCharacters (field: FieldKey) {
    this.remainingCharacters[field] = this.maxLength[field]- this.form[field].length;
  },
  async fetchCaseDetails (caseId: string) {
    try {
      const response = await this.$store.dispatch('radcase/fetchCaseById', caseId);
      const breedName = this.breeds.find((b: IBreedModel) => b.id === response.breedId)?.name ?? 'Unknown Breed';
      this.caseData = {
        radCase: response,
        breedName: breedName,
        clinicName: this.clinic.name,
        email: this.clinic.email,
        age: getAge(response.birthDate)
      };
      this.form.findings = response.findings || '';
      this.form.interpretation = response.interpretation || '';
      this.form.internalMedicineRecommandations = response.internalMedicineRecommandations || '';
      this.form.signature = response.signature || '';

      this.fileIdStatues = await this.$store.dispatch('radcase/fetchCaseFileIdsByCaseId', caseId);
    } catch (error) {
      console.error('Failed to fetch case details:', error);
    }
  },
  async fetchFileById (fileId: number): Promise<{ url: string; mimeType: string } | null> {
    try {
      const fileBlob = await this.$store.dispatch('radcase/fetchCaseFileById', fileId);
      const fileUrl = URL.createObjectURL(fileBlob);
      return {
        url: fileUrl,
        mimeType: fileBlob.type
      };
    } catch (error) {
      console.error('Error fetching file:', error);
      return null;
    }
  },
  filterCasesbyStatus (status: number): void {
    console.log(status);
  },
  async onClickSubmit () {
    const formData = new FormData();
    formData.append('caseId', JSON.stringify(this.caseData?.radCase.id));
    formData.append('findings', this.form.findings);
    formData.append('internalMedicineRecommandations', this.form.internalMedicineRecommandations);
    formData.append('signature', this.form.signature);
    formData.append('interpretation', this.form.interpretation);
    for (const filePreview of this.filePreviews) {
      // const blob = new Blob([filePreview.src as ArrayBuffer], { type: filePreview.file.type });
      let blob;
      if (filePreview.src instanceof ArrayBuffer) {
        blob = new Blob([filePreview.src], { type: filePreview.file.type });
      } else if (filePreview.src instanceof Blob) {
        blob = filePreview.src;
      } else if (typeof filePreview.src === 'string') {
        const response = await fetch(filePreview.src);
        const arrayBuffer = await response.arrayBuffer();
        blob = new Blob([arrayBuffer], { type: filePreview.file.type });
      } else {
        throw new Error('Unsupported file source type.');
      }
      console.log(`Sending file: ${filePreview.name}, Size: ${blob.size} bytes`);

      formData.append('files', blob, filePreview.name);
    }
    try {
      dispatch('radcase/radiologistSubmit', formData);
      this.$router.push({ name: 'cases' });
    } catch (e) {
      console.log(e);
    }
  },
  onClickBack () {
    this.$router.push({ name: 'cases' });
  },
  async onPDFClick () {
    await dispatch('report/createPage', 2);
    navigate(this.$route, NavigationMethods.push, '/casereport');
  },
  updateFindings (event: InputEvent): void {
    const target = event.target as HTMLDivElement;
    this.form.findings = target.innerHTML;
  },
  updateInterpretation (event: InputEvent): void {
    const target = event.target as HTMLDivElement;
    this.form.interpretation = target.innerHTML;
  },
  updateInternalMedicineRecommandations (event: InputEvent): void {
    const target = event.target as HTMLDivElement;
    this.form.internalMedicineRecommandations = target.innerHTML;
  },
  updateSignature (event: InputEvent): void {
    const target = event.target as HTMLDivElement;
    this.form.signature = target.innerHTML;
  },
  handleImageError (event: Event) {
    const img = event.target as HTMLImageElement;
    console.error(`Failed to load image: ${img.alt}`);
    img.alt = 'Image not available';
  },
  updateFilePreviews (files: IFilePreview[]) {
    this.filePreviews = files;
  },
  openFileInNewTab (url: string) {
    window.open(url, '_blank');
  },
  isImage (mimeType: string): boolean {
    return mimeType.startsWith('image/');
  }
} });
