








































import Vue from 'vue';
import { faBookOpen, faDesktop, faPowerOff, faCog, faChartBar, faListUl } from '@fortawesome/free-solid-svg-icons';
import IconLink from './IconLink.vue';
import { isAppLocal } from '@/helpers/config';
import { get } from 'vuex-pathify';
import { IPermissionProfileModel } from '../../api/Models';

export default Vue.extend({
  components: {
    IconLink
  },
  computed: {
    isRoot: get<boolean>('permissionProfile/isRoot'),
    permissionProfile: get<IPermissionProfileModel>('permissionProfile/profile')

  },
  data () {
    return {
      monitor: faDesktop,
      bookOpen: faBookOpen,
      powerOff: faPowerOff,
      reportOpen: faChartBar,
      listIcon: faListUl,
      cog: faCog
    };
  },
  methods: {
    isAppLocal,
    showCase (): boolean {
      return this.isRoot || this.permissionProfile.radiologistCase || this.permissionProfile.doctorCase;
    },
    isRadiologist (): boolean {
      return this.permissionProfile.radiologistCase;
    }
  }
});
