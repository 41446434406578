import { Module } from 'vuex';
import { commit, make, dispatch } from 'vuex-pathify';
import vuexStore, { IRootState } from '@/state';
import { IImageModel } from '@/api/Models';

export interface IReportImage extends IImageModel {
  index: number;
}

interface IAddImagePayload {
  image: Partial<IReportImage>;
  pageIndex: number;
}

interface ISetContentPayload {
  htmlContent: string;
  pageIndex: number;
}

interface ISetPageImagesPayload {
  images: IReportImage[];
  pageIndex: number;
}

export interface IReportPage {
  images: IReportImage[];
  imageCount: number;
  isActive: boolean;
  htmlContent: string;
}

export interface IReportState {
  pages: IReportPage[];
  title?: string;
}

const _state: IReportState = {
  pages: [],
  title: undefined
};

const caseReport: Module<IReportState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state),
    addPage (state, page: IReportPage) {
      state.pages.push(page);
    },
    setPageImages (state, setPageImagePayload: ISetPageImagesPayload) {
      state.pages[setPageImagePayload.pageIndex].images = setPageImagePayload.images;
    },
    flush (state) {
      state.pages = [];
    }
  },
  actions: {
    createPage (context, imageCount: number) {
      const page: IReportPage = {
        imageCount,
        images: [],
        isActive: false,
        htmlContent: ''
      };

      commit('report/addPage', page);
    },
    async addImage (context, payload: IAddImagePayload): Promise<IReportImage[]> {
      const image = await dispatch('study/fetchImage', payload.image.id);
      const images: IReportImage[] = vuexStore.get(`report/pages[${payload.pageIndex}]@images`);

      const filteredImages = images.filter(img => img.index !== payload.image.index);

      const updatedImages = [...filteredImages, { ...image, index: payload.image.index }];
      const setPageImagesPayload = { images: updatedImages, pageIndex: payload.pageIndex };

      commit('report/setPageImages', setPageImagesPayload);

      return updatedImages;
    },
    updateHtmlContent (context, payload: ISetContentPayload): void {
      const pages: IReportPage[] = [...this.get('report/pages')];

      const newPage: IReportPage = {
        ...pages[payload.pageIndex],
        htmlContent: payload.htmlContent
      };
      pages[payload.pageIndex] = newPage;

      commit('report/setPages', pages);
    },
    disablePage (context, pageIndex: number): void {
      const pages: IReportPage[] = [...this.get('report/pages')];

      pages[pageIndex].isActive = false;
      commit('report/setPages', pages);
    },
    activatePage (context, pageIndex: number): void {
      const pages: IReportPage[] = [...this.get('report/pages')];

      pages.forEach(page => {
        page.isActive = false;
      });

      const newPage: IReportPage = {
        ...pages[pageIndex],
        isActive: true
      };
      pages[pageIndex] = newPage;

      commit('report/setPages', pages);
    }
  },
  namespaced: true
};

export default caseReport;
