/* eslint-disable @typescript-eslint/no-explicit-any */
import { dispatch } from 'vuex-pathify';

import {
  faEllipsisV, faEllipsisH, faEnvelope, faEdit, faFileAlt,
  faSearchPlus, faCropAlt, faEyeSlash, faCircle,
  faRulerCombined, faRuler, faRedo, faFileExport,
  faTrashAlt, faSquare, faSearch, IconDefinition,
  faFileDownload, faHandPaper, faSync, faInfo, faDog, faShareSquare
} from '@fortawesome/free-solid-svg-icons';

import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

import eventBus from '@/eventBus';
import EmailModal from '../modals/EmailModal.vue';
import CreateCaseModal from '../modals/CreateCaseModal.vue';

import DicomSendModal from '../modals/DicomSendModal.vue';
import EditAnnotationsModal from '../modals/edit/EditAnnotationsModal.vue';
import DownloadImagesModal from '../modals/DownloadImagesModal.vue';
import DicomDump from '../modals/DicomDump.vue';
import vuexStore from '@/state';
import { isAppCloud, isAppLocal } from '@/helpers/config';
import { ClinicType } from '@/api/Models';
import { configureTextMarker, toggleMeasurementsVisibility, clearMeasurements } from '@/state/caseViewer/tools/helpers';
import { getGlobalToolState } from '@/state/caseViewer/general-helpers';
import { IDisplay } from '@/state/caseViewer/images/images-types';

export interface ITool {
  id: string;
  label: string;
  icon?: IconDefinition | string;
  textIcon?: string;
  clinicType?: ClinicType;
  isSingleImageOnly?: boolean;
  hidden?: boolean;
  action: () => void;
}

export interface IDropdownToolGroup {
  tools: ITool[];
  icon: IconDefinition;
}

function createCornerstoneTool (id: string, label: string, toolName: string, icon?: IconDefinition | string): ITool {
  return {
    id,
    label,
    icon,
    action: () => {
      dispatch('caseViewer/tools/resetEnabledTools');
      dispatch('caseViewer/tools/activateTool', { toolName, id });

      const isSingleImage = vuexStore.get('caseViewer/images/isSingleImage');
      if (toolName === 'Zoom' && isSingleImage) {
        dispatch('caseViewer/tools/enableTool', 'ScaleOverlay');
      }
    }
  };
}

export function getDropdownGroupedTools (dicomForms = true, advancedTools = true, isDoctorCase = false): IDropdownToolGroup[] {
  return [
    {
      tools: isDoctorCase ? [
        { id: 'rad-case', label: 'Create Case', icon: faFileAlt, action: () => eventBus.launchModal(CreateCaseModal) },
      ] : [],
      icon: faFileAlt
    },
    {
      tools: isDoctorCase ? [
        { id: 'rad-case', label: 'Create Case', icon: faFileAlt, action: () => eventBus.launchModal(CreateCaseModal) }
      ] : [],
      icon: faFileAlt
    },
    {
      tools: advancedTools ? [
        { ...createCornerstoneTool('vhs', 'VHS', 'VHS'), textIcon: 'VHS', isSingleImageOnly: true, clinicType: ClinicType.Veterinary, hidden: isAppLocal() },
        { ...createCornerstoneTool('tplo', 'TPLO', 'TPLO'), textIcon: 'TPLO', isSingleImageOnly: true, clinicType: ClinicType.Veterinary, hidden: isAppLocal() },
        { ...createCornerstoneTool('tta', 'TTA', 'TTA'), textIcon: 'TTA', isSingleImageOnly: true, clinicType: ClinicType.Veterinary, hidden: isAppLocal() }
      ] : [],
      icon: faDog
    }
  ];
}

export function getToolbarTools (caseId: number | undefined = undefined): ITool[][] {
  return [
    [
      createCornerstoneTool('angle', 'Angle', 'Angle', faRulerCombined),
      { ...createCornerstoneTool('bidirectional', 'Bidirectional', 'Bidirectional'), icon: require('@/assets/caseViewer/bi-directional.svg') },
      createCornerstoneTool('length', 'Length', 'Length', faRuler),
      { ...createCornerstoneTool('arrow-annotate', 'Arrow Annotate', 'ArrowAnnotate'), icon: require('@/assets/caseViewer/arrow-annotate.svg') },
      {
        id: 'text-marker',
        label: 'Text Only',
        action: () => {
          dispatch('caseViewer/tools/activateTool', { toolName: 'TextMarker', id: 'text-marker' });
          const element: HTMLElement = vuexStore.get('caseViewer/images/activeDisplay@enabledHtmlElement');
          configureTextMarker(element);
        },
        textIcon: 'TEXT'
      },
      {
        id: 'text-marker-l',
        label: 'Left Marker',
        action: () => {
          dispatch('caseViewer/tools/activateTool', { toolName: 'TextMarker', id: 'text-marker-l' });
          const element: HTMLElement = vuexStore.get('caseViewer/images/activeDisplay@enabledHtmlElement');
          configureTextMarker(element, 'L');
        },
        textIcon: 'L'
      },
      {
        id: 'text-marker-r',
        label: 'Right Marker',
        action: () => {
          dispatch('caseViewer/tools/activateTool', { toolName: 'TextMarker', id: 'text-marker-r' });
          const element: HTMLElement = vuexStore.get('caseViewer/images/activeDisplay@enabledHtmlElement');
          configureTextMarker(element, 'R');
        },
        textIcon: 'R'
      },
      { ...createCornerstoneTool('cobb-angle', 'Cobb Angle', 'CobbAngle'), icon: require('@/assets/caseViewer/cobb-angle.svg') },
      {
        id: 'toggle',
        label: 'Hide Annotations',
        action: () => {
          const enabledHtmlElement: HTMLElement = vuexStore.get('caseViewer/images/activeDisplay@enabledHtmlElement');
          if (!enabledHtmlElement) throw new Error('Enabled element not found');
          toggleMeasurementsVisibility(getGlobalToolState(), enabledHtmlElement);
        },
        icon: faEyeSlash
      },
      {
        id: 'clear',
        label: 'Delete Annotations',
        action: () => {
          const display: IDisplay = vuexStore.get('caseViewer/images/activeDisplay');
          clearMeasurements(getGlobalToolState(), display);
        },
        icon: faTrashAlt
      }
    ],
    [
      createCornerstoneTool('pan', 'Pan', 'Pan', faHandPaper),
      { ...createCornerstoneTool('wwc', 'Window Leveling', 'Wwwc'), icon: require('@/assets/caseViewer/wwwc.svg') },
      createCornerstoneTool('zoom', 'Zoom', 'Zoom', faSearch),
      createCornerstoneTool('magnify', 'Magnify', 'Magnify', faSearchPlus),
      { id: 'crop-case', label: 'Crop', action: async () => await dispatch('caseViewer/tools/activateCrop', { caseId }), icon: faCropAlt, isSingleImageOnly: true },
      {
        id: 'invert',
        label: 'Invert',
        icon: require('@/assets/caseViewer/invert.svg'),
        action: () => {
          dispatch('caseViewer/images/setViewport', (viewport: any) => {
            return { ...viewport, invert: !viewport.invert };
          });

          dispatch('caseViewer/images/saveViewport');
        }
      },
      {
        id: 'rotate-right',
        label: 'Rotate Right',
        icon: faSync,
        action: () => {
          dispatch('caseViewer/images/setViewport', (viewport: any) => {
            return { ...viewport, rotation: viewport.rotation + 90 };
          });

          dispatch('caseViewer/images/saveViewport');
        }
      },
      {
        id: 'flip-horizontal',
        label: 'Flip Horizontal',
        icon: faEllipsisH,
        action: () => {
          dispatch('caseViewer/images/setViewport', (viewport: any) => {
            return { ...viewport, hflip: !viewport.hflip };
          });

          dispatch('caseViewer/images/saveViewport');
        }
      },
      {
        id: 'flip-vertical',
        label: 'Flip Vertical',
        icon: faEllipsisV,
        action: () => {
          dispatch('caseViewer/images/setViewport', (viewport: any) => {
            return { ...viewport, vflip: !viewport.vflip };
          });

          dispatch('caseViewer/images/saveViewport');
        }
      },
      {
        id: 'reset',
        label: 'Reset',
        action: () => {
          dispatch('caseViewer/images/resetViewportAndCrop');
        },
        icon: faRedo
      }
    ]
  ];
}
