import Vue from 'vue';
import { Module, Store } from 'vuex';
import { once } from 'lodash';
import cornerstone from 'cornerstone-core';
import { commit, dispatch, make } from 'vuex-pathify';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

import vuexStore, { IRootState } from '../../';
import { IDisplay, ILoadImagePayload, Viewports, IInsertElementPayload, IGridPayload } from './images-types';
import { getDisplay, getDisplayIndex, createCaseCornerstoneImageId, cropImage } from '../general-helpers';
import { IImageModel } from '@/api/Models';

export interface IImagesState {
  viewports?: Viewports;
  activeDisplay: IDisplay;
  displayGrid: IDisplay[];
}

const initialDisplay: IDisplay = {
  enabledHtmlElement: undefined,
  image: undefined,
  row: 0,
  column: 0
};

const _state: IImagesState = {
  viewports: undefined,
  activeDisplay: initialDisplay,
  displayGrid: [initialDisplay]
};

const images: Module<IImagesState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state),
    insertImage (state, payload: any) {
      const displayIndex = getDisplayIndex(state.displayGrid, payload);
      const display = state.displayGrid[displayIndex];
      state.displayGrid.splice(displayIndex, 1, { ...display, image: payload.image });
    },
    insertElement (state, payload: IInsertElementPayload) {
      const displayIndex = getDisplayIndex(state.displayGrid, payload);
      const display = state.displayGrid[displayIndex];
      state.displayGrid.splice(displayIndex, 1, { ...display, enabledHtmlElement: payload.element });
    },
    flush (state): void {
      state.activeDisplay = initialDisplay;
      state.viewports = undefined;
      state.displayGrid = [initialDisplay];
    }
  },
  getters: {
    getIsSingleImage (state): boolean {
      return state.displayGrid.length === 1;
    },
    activeDisplay (state): IDisplay {
      return state.activeDisplay;
    }
  },
  actions: {
    initialize: once((context, store: Store<IRootState>) => {
      cornerstoneWebImageLoader.external.cornerstone = cornerstone;

      const token: string|undefined = Vue.auth.token();
      cornerstoneWebImageLoader.configure({
        beforeSend: (xhr: XMLHttpRequest) => {
          xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }
      });

      store.watch(state => {
        return {
          activeDisplay: state.caseViewer.images.activeDisplay || initialDisplay,
          displayGrid: state.caseViewer.images.displayGrid || []
        };
      }, async (newState, oldState) => {
        const { displayGrid, activeDisplay } = newState;
        const { enabledHtmlElement, image } = activeDisplay;

        const hasActiveDisplayImageChanged = activeDisplay.image?.imageId !== oldState.activeDisplay.image?.imageId;
        if (hasActiveDisplayImageChanged) {
          const originalImage = store.state.study.images.find(imageItem => {
            return createCaseCornerstoneImageId(imageItem.id as number) === activeDisplay.image?.imageId;
          });
          commit('study/setSelectedImage', originalImage);
        }

        const isGridEmptyOfImages = displayGrid.every(display => !display.image);
        const wasFlushed = !enabledHtmlElement && oldState.activeDisplay.enabledHtmlElement;
        if (isGridEmptyOfImages && !wasFlushed) {
          await dispatch('caseViewer/images/loadImagesIntoEmptyGrid');
        }

        const hasGridLayoutChanged = displayGrid.length !== oldState.displayGrid.length;
        const isGridLoaded = displayGrid.every(display => display.image && display.enabledHtmlElement);
        if (hasGridLayoutChanged && isGridLoaded) {
          dispatch('caseViewer/tools/resetActiveTool');
          dispatch('caseViewer/tools/resetEnabledTools');
        }

        const relevantDisplay = getDisplay(displayGrid, { row: activeDisplay.row, column: activeDisplay.column });
        const imageChanged = relevantDisplay?.image?.imageId !== activeDisplay.image?.imageId;
        const elementChanged = relevantDisplay?.enabledHtmlElement !== activeDisplay.enabledHtmlElement;
        if (imageChanged || elementChanged) {
          const payload: IGridPayload = { row: activeDisplay.row, column: activeDisplay.column };
          dispatch('caseViewer/images/refreshActiveDisplay', payload);
        }

        const didLoadedImageChange = image?.imageId !== oldState.activeDisplay.image?.imageId;
        if (image && enabledHtmlElement && didLoadedImageChange) {
          await dispatch('caseViewer/images/syncViewportAndCrop');
        }
      });
    }),
    enableElement (context, element: HTMLElement): void {
      cornerstone.enable(element);
      commit('caseViewer/images/setEnabledHtmlElement', element);
    },
    resetLayers (context, display?: IDisplay): void {
      const { enabledHtmlElement, image } = display || context.state.activeDisplay;
      if (enabledHtmlElement && image) {
        const layers = cornerstone.getLayers(enabledHtmlElement);
        layers.forEach(layer => {
          cornerstone.removeLayer(enabledHtmlElement, layer.layerId);
        });

        cornerstone.displayImage(enabledHtmlElement, image);
        dispatch('caseViewer/images/resetViewport', display);
      }
    },
    resetViewport (context, display?: IDisplay): void {
      const { enabledHtmlElement } = display || context.state.activeDisplay;
      cornerstone.reset(enabledHtmlElement as HTMLElement);
    },
    resetViewportAndCrop (): void {
      dispatch('caseViewer/tools/resetCrop');
      dispatch('caseViewer/images/resetLayers');
      //dispatch('caseViewer/images/saveViewport');
    },
    setViewport (context, callback: (viewport: any) => any): void {
      const enabledHtmlElement = context.state.activeDisplay.enabledHtmlElement;
      if (!enabledHtmlElement) {
        throw new Error('Enabled element not found');
      }
      const currentViewport = cornerstone.getViewport(enabledHtmlElement);
      const newViewport = callback(currentViewport);
      cornerstone.setViewport(enabledHtmlElement, { ...newViewport });
    },
    syncViewport (context, display?: IDisplay) {
      const { enabledHtmlElement, image } = display || context.state.activeDisplay;
      const isSingleImage: boolean = this.get('caseViewer/images/isSingleImage');

      if (!enabledHtmlElement || !image) {
        throw new Error('Missing enabled element or a loaded image');
      }

      const savedViewport = context.state.viewports?.[image.imageId] ? { ...context.state.viewports?.[image.imageId] } : undefined;
      if (!isSingleImage && savedViewport?.multiImageScale) {
        savedViewport.scale = savedViewport.multiImageScale;
      }
      if (savedViewport) {
        cornerstone.setViewport(enabledHtmlElement, savedViewport);
      }
    },
    async syncViewportAndCrop (context, display?: IDisplay): Promise<void> {
      dispatch('caseViewer/images/resetLayers', display);

      const { image } = display || context.state.activeDisplay;
      const crops = vuexStore.get('caseViewer/tools/crops');

      const doesLoadedImageHaveCrops = image && crops?.[image.imageId];
      if (doesLoadedImageHaveCrops) {
        await cropImage(display || context.state.activeDisplay, crops);
      }

      //dispatch('viewer/images/syncViewport', display);
    },
    saveViewport (context, display?: IDisplay) {
      const currentViewports = context.state.viewports;
      const isSingleImage: boolean = this.get('caseViewer/images/isSingleImage');
      const { enabledHtmlElement, image } = display || context.state.activeDisplay;

      if (!enabledHtmlElement || !image) {
        throw new Error('Missing enabled element or a loaded image');
      }

      const newViewport = cornerstone.getViewport(enabledHtmlElement);

      if (!isSingleImage && currentViewports && currentViewports[image.imageId]) {
        const currentViewport = currentViewports[image.imageId];
        newViewport.multiImageScale = newViewport.scale;
        newViewport.scale = currentViewport.scale;
      }
      commit('caseViewer/images/setViewports', {
        ...currentViewports,
        [image.imageId]: { ...newViewport }
      });
    },
    refreshActiveDisplay (context, payload: IGridPayload): void {
      const displayGrid = context.state.displayGrid;
      let newActiveDisplay = getDisplay(displayGrid, payload);
      if (!newActiveDisplay) {
        newActiveDisplay = getDisplay(displayGrid, { row: 0, column: 0 });
      }
      commit('caseViewer/images/setActiveDisplay', newActiveDisplay);
    },

    loadImagesIntoEmptyGrid (context): Promise<any[]> {
      const displayGrid = context.state.displayGrid;
      const studyIMages = context.rootState.study.images;

      let imageIndex = 0;
      const promises = displayGrid.map(display => {
        const image: IImageModel | undefined = studyIMages[imageIndex++];
        if (!image) {
          return;
        }

        const payload: ILoadImagePayload = {
          imageId: image.id as number,
          row: display.row,
          column: display.column
        };
        return dispatch('caseViewer/images/loadImageIntoGrid', payload);
      });
      return Promise.all(promises);
    },
    async loadImageIntoGrid (context, payload: ILoadImagePayload): Promise<void> {
      const displayGrid = [...context.state.displayGrid];
      const relevantDisplayIndex = getDisplayIndex(displayGrid, payload);
      if (relevantDisplayIndex === -1) {
        throw new Error('Error updating display grid');
      }

      const cornerstoneImageId = createCaseCornerstoneImageId(payload.imageId);
      const isInsertionInvalid = displayGrid.some(display => {
        const doesImageExists = display.image?.imageId === cornerstoneImageId;
        const areCoordinatesDifferent = display.row !== payload.row || display.column !== payload.column;
        return doesImageExists && areCoordinatesDifferent;
      });
      if (isInsertionInvalid) {
        Vue.toasted.error('Cannot insert an image already existing in the grid');
        return;
      }
      const image = await cornerstone.loadAndCacheImage(cornerstoneImageId);
      commit('caseViewer/images/insertImage', { row: payload.row, column: payload.column, image });
    }
  },
  namespaced: true
};

export default images;
