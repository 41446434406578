











import Vue from 'vue';
import { commit, dispatch, get } from 'vuex-pathify';
import { IImageModel } from '@/api/Models';
import CaseThumbnailList from '@/components/swiper/CaseThumbnailList.vue';
import { IDisplay, ILoadImagePayload } from '@/state/caseViewer/images/images-types';

export default Vue.extend({
  components: {
    CaseThumbnailList
  },
  computed: {
    images: get<IImageModel[]>('radcase/images'),
    activeDisplay: get<IDisplay>('caseViewer/images/activeDisplay')
  },
  mounted () {
    commit('study/setSelectedImage', this.images[0]);
  },
  methods: {
    async onSelection (image: IImageModel): Promise<void> {
      const { row, column } = this.activeDisplay;
      const payload: ILoadImagePayload = {
        row,
        column,
        imageId: image.id as number
      };
      await dispatch('caseViewer/images/loadImageIntoGrid', payload);
    },
    onDragStart (event: DragEvent, image: IImageModel): void {
      const imageId = image.id?.toString() as string;
      return event.dataTransfer?.setData('imageId', imageId);
    }
  }
});
