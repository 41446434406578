import Vue from 'vue';
import axios from 'axios';
import { Module } from 'vuex';
import jwt_decode from 'jwt-decode';
import { commit, dispatch, make } from 'vuex-pathify';

import { SwalTitles } from '../enums';
import { Modality } from '@/api/Models';
import vuexStore, { IRootState } from '../index';
import { tryGetConfig, isAppLocal, isAppCloud } from '@/helpers/config';
export interface IAuthState {
  userId?: number;
}

const _state: IAuthState = {
  userId: undefined
};

interface ICredentials {
  username: string;
  password: string;
}

interface IJwt {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  nameid: string;
  nbf: number;
}

const auth: Module<IAuthState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state),
    setUserId (state, token: string) {
      const parsedToken: IJwt = jwt_decode(token);
      state.userId = parsedToken?.nameid ? parseInt(parsedToken.nameid) : undefined;
    }
  },

  actions: {
    setUserIdFromToken (): void {
      const token = Vue.auth.token();
      if (token) {
        commit('auth/setUserId', token);
      }
    },

    async login (context, credentials: ICredentials): Promise<void> {
      const { username, password } = credentials;

      const response = await Vue.auth.login({
        data: { username, password }
      });

      const jwt = response.headers?.authorization;

      if (!jwt) {
        throw new Error('Error logging in');
      }
      commit('auth/setUserId', jwt);


      if (!isAppLocal()) return;
      try {
        await dispatch('generator/togglePower', true);
      } catch (ex) {
        if (ex?.response?.status === 501) {
          commit('generator/setGeneratorDisabled', true);
          console.info('Generator disabled.', ex);
        } else {
          console.error('failed to turn-on generator', ex);
        }
      }
    },

    async logout (context, fromPathName: string): Promise<void> {
      const modality: Modality | undefined = vuexStore.get('sensor/sensor@modality');
      const isGeneral = modality === Modality.General;

      let confirmationTitle = isGeneral ? SwalTitles.LogOut : SwalTitles.ExitApp;

      const dicomSendRequests: number = vuexStore.get('dicomServer/requestsInProgress');
      if (dicomSendRequests > 0) {
        confirmationTitle = SwalTitles.RequestsDetectedOnExit;
      }

      if (fromPathName && Vue.auth.token()) {
        const confirmationModal = await Vue.swal.fire({
          title: confirmationTitle,
          showCancelButton: true
        });

        if (confirmationModal.isDismissed) return;
      }

      if (Vue.auth.token() && isAppLocal()) {
        try {
          await dispatch('generator/togglePower', false);
          await dispatch('sensor/closeConnection');
        } catch (ex) {
          console.error('failed to turn-off generator or close sensor connection', ex);
        }
      } else {
        console.warn('logout initiated without a token. Wont attempt to turn off the generator.');
      }

      await Vue.auth.logout({ makeRequest: false, redirect: undefined });

      if (isAppCloud()) {
        window.location.replace('/login');
        return;
      }

      if (isGeneral) {
        window.location.replace('/vue/login');
        return;
      }

      const launcherDisabled = tryGetConfig('LAUNCHER_DISABLED');

      if (launcherDisabled !== 'true') {
        axios.get('http://localhost:4044/exit');
      } else {
        console.info('Launcher disabled. Will not exit app.');
        window.location.replace('/vue/login');
      }
    }
  },
  namespaced: true
};

export default auth;
