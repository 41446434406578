

















import Vue from 'vue';
import { dispatch, get, sync } from 'vuex-pathify';
import { IDisplay, ILoadImagePayload } from '@/state/caseViewer/images/images-types';
import Display from './Display.vue';
import { IImageModel } from '@/api/Models';

function getGridTemplate (max: number): string {
  return max === 3 ? '33.333% 33.333% 33.333%' :
    max === 2 ? '50% 50%' : '100%';
}

export default Vue.extend({
  components: {
    Display
  },
  computed: {
    images: get<IImageModel[]>('study/images'),
    displayGrid: get<IDisplay[]>('caseViewer/images/displayGrid'),
    activeDisplay: sync<IDisplay>('caseViewer/images/activeDisplay'),
    gridContainerStyle (): any {
      const maxRows = this.displayGrid.reduce((acc, display) => {
        return Math.max(acc, display.row);
      }, 0) + 1;

      const maxColumns = this.displayGrid.reduce((acc, display) => {
        return Math.max(acc, display.column);
      }, 0) + 1;

      const styleObject = {
        gridTemplateRows: getGridTemplate(maxRows),
        gridTemplateColumns: getGridTemplate(maxColumns)
      };
      return styleObject;
    },
    sortedDisplayGrid (): IDisplay[] {
      const displayGridClone = [...this.displayGrid];
      return displayGridClone.sort((displayA, displayB) => {
        if (displayA.row !== displayB.row) {
          return displayA.row > displayB.row ? 1 : -1;
        }
        return displayA.column > displayB.column ? 1 : -1;
      });
    }
  },
  methods: {
    triggerResize (): void {
      const displays = this.$refs.displays as any[];
      displays.forEach(display => {
        display.scale();
        display.resizeImage();
      });
    },
    isActive (display: IDisplay): boolean {
      return display.row === this.activeDisplay.row && display.column === this.activeDisplay.column;
    },
    onMousedown (display: IDisplay): void {
      if (!display.image || !display.enabledHtmlElement || this.isActive(display)) return;
      this.activeDisplay = display;
    },
    onDrop (event: DragEvent, display: IDisplay): void {
      this.activeDisplay = display;

      const imageId = event.dataTransfer?.getData('imageId');
      if (!imageId) {
        return;
      }

      const payload: ILoadImagePayload = {
        row: display.row,
        column: display.column,
        imageId: parseInt(imageId)
      };
      dispatch('caseViewer/images/loadImageIntoGrid', payload);
    }
  }
});
