/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import { Module } from 'vuex';
import Hammer from 'hammerjs';
import { once, omit } from 'lodash';
import { commit, dispatch } from 'vuex-pathify';
import cornerstoneTools, { getModule, Handles } from 'cornerstone-tools';

import { IRootState } from '../..';
import { allCornerstoneTools } from './allCornerstoneTools';
import { removeAllFromOverlay } from './custom-tools/utils/toolOverlay';
import { SwalTitles } from '@/state/enums';
import { IDisplay } from '../images/images-types';
import { resetCursor } from './helpers';
import { cropImage } from '../general-helpers';

const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');

export type Crops = {
  [imageId: string]: Handles;
};

export interface IToolsState {
  crops?: Crops;
  activeToolId?: string;
  caseId?: number;
}

const _state: IToolsState = {
  crops: undefined,
  activeToolId: undefined,
  caseId: undefined
};

const tools: Module<IToolsState, IRootState> = {
  state: _state,
  mutations: {
    // using ...make.mutations here for some reason breaks the state
    setCrops (state, crops: Crops): void {
      state.crops = crops;
    },
    setActiveToolId (state, id: string): void{
      state.activeToolId = id;
    },
    setCaseId (state, id: number): void {
      state.caseId= id;
    },
    flush (state): void {
      state.crops = undefined;
    }
  },
  actions: {
    initialize: once(() => {
      const segmentation = getModule('segmentation');
      segmentation.configuration.segmentsPerLabelmap = 0;

      cornerstoneTools.external.cornerstone = cornerstone;
      cornerstoneTools.external.Hammer = Hammer;
      cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
      cornerstoneTools.init({
        showSVGCursors: true,
        globalToolSyncEnabled: true
      });

      const fontSize = window.innerWidth < 1920 ? '15px' : '18px';
      cornerstoneTools.textStyle.setFont(`${fontSize} Arial`);

      allCornerstoneTools.forEach(Tool => {
        cornerstoneTools.addTool(Tool.Tool, Tool.options);
        // annotation tools need start passive to make sure they render any saved measurements
        if (Tool.Tool.prototype instanceof BaseAnnotationTool) {
          const toolName = new Tool.Tool().name;
          cornerstoneTools.setToolPassive(toolName);
        }
      });

      cornerstoneTools.setToolActive('Eraser', { mouseButtonMask: 2 });
    }),
    restoreToolState (context, toolState: any): void {
      if (toolState && Object.keys(toolState).length > 0) {
        cornerstoneTools.globalImageIdSpecificToolStateManager.restoreToolState(toolState);
      }
    },
    activateTool (context, payload: {toolName: string; id: string}): void {
      cornerstoneTools.setToolActive(payload.toolName, { mouseButtonMask: 1 });
      commit('caseViewer/tools/setActiveToolId', payload.id);
    },
    enableTool (context, toolName: string) {
      cornerstoneTools.setToolEnabled(toolName);
    },
    resetEnabledTools (): void {
      const enabledTools = cornerstoneTools.store.state.tools.filter(tool => tool.mode === 'enabled');
      enabledTools.forEach(tool => cornerstoneTools.setToolPassive(tool.name));
    },
    resetActiveTool (): void {
      const activeTools = cornerstoneTools.store.state.tools.filter(
        tool => tool.mode === 'active'
      );

      const mainTool = activeTools.find(tool => tool._options.mouseButtonMask.includes(1));
      if (mainTool) {
        cornerstoneTools.setToolPassive(mainTool.name);
      }

      const displayGrid: IDisplay[] = this.get('caseViewer/images/displayGrid');
      displayGrid.forEach(display => {
        const element = display.enabledHtmlElement;
        if (!element) return;

        removeAllFromOverlay(element);
        resetCursor(element, cornerstoneTools.store.state);
      });

      commit('caseViewer/tools/setActiveToolId', undefined);
    },
    async activateCrop (context, payload: { caseId: string }): Promise<void> {
      const { caseId } = payload;
      const loadedImage = this.get('caseViewer/images/activeDisplay@image');
      if (context.state.crops?.[loadedImage?.imageId]) {
        await dispatch('caseViewer/tools/launchResetCropModal');
      }
      if (loadedImage && !context.state.crops?.[loadedImage.imageId]) {
        Vue.toasted.show('Viewport resetted!');
        dispatch('caseViewer/images/resetViewport');
        commit('caseViewer/tools/setCaseId', caseId);
        dispatch('caseViewer/tools/activateTool', { toolName: 'Crop', id: 'crop-case' });
      }
    },
    async cropViewport (context, cropArea: Handles): Promise<void> {
      const activeDisplay: IDisplay = this.get('caseViewer/images/activeDisplay');
      await cropImage(activeDisplay, cropArea);

      const currentCrops = context.state.crops;
      commit('caseViewer/tools/setCrops', {
        ...currentCrops,
        [activeDisplay.image.imageId]: cropArea
      });
      dispatch('caseViewer/tools/resetActiveTool');
    },
    resetCrop (context): void {
      const loadedImage = this.get('caseViewer/images/activeDisplay@image');
      const currentCrops = context.state.crops;
      commit('caseViewer/tools/setCrops', omit(currentCrops, loadedImage.imageId));
    },
    async launchResetCropModal (): Promise<void> {
      const resetCropModal = await Vue.swal.fire({
        title: SwalTitles.ResetCrop,
        text: 'Please reset the current crop before cropping again',
        confirmButtonText: 'Confirm',
        showCancelButton: true
      });

      if (resetCropModal.isConfirmed) {
        dispatch('caseViewer/images/resetViewportAndCrop');
      }
    }
  },
  namespaced: true
};

export default tools;
