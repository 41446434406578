import dcmjs from 'dcmjs';
import { once } from 'lodash';
import cornerstone from 'cornerstone-core';
import { commit, make } from 'vuex-pathify';
import { ActionContext, Module } from 'vuex';

import { IRootState } from '../..';
import FMSAxios from '@/api/FMSAxios';
import { createCaseCornerstoneImageId } from '../general-helpers';
import { IImageModel } from '@/api/Models';

export enum Modules {
  PIXEL_MODULE = 'imagePixelModule',
  PLANE_MODULE = 'imagePlaneModule',
}

export interface IMetadataState {
  imagesMetadata?: {
    [imageId: string]: any;
  };
}

const _state: IMetadataState = {
  imagesMetadata: undefined
};

const metadata: Module<IMetadataState, IRootState> = {
  state: _state,
  getters: {
    getImageMetadata (state): (imageId: string) => any | undefined {
      return (imageId: string) => {
        return state.imagesMetadata?.[imageId];
      };
    },
    getIsPixelSpacingFound (state): (imageId: string) => boolean {
      return (imageId: string) => {
        const imageMetadata = state.imagesMetadata?.[imageId];
        return !!imageMetadata?.PixelSpacing || !!imageMetadata?.ImagerPixelSpacing;
      };
    }
  },
  mutations: {
    ...make.mutations(_state),
    flush (state): void {
      state.imagesMetadata = {};
    }
  },
  actions: {
    initialize: once((context: ActionContext<IMetadataState, IRootState>) => {
      // cornerstone-tools and cornerstone image loaders use the provider to fetch DICOM tags
      cornerstone.metaData.addProvider((type, imageId) => {
        const imageMetadata = context.state.imagesMetadata?.[imageId];
        if (!imageMetadata) return undefined;

        const { SamplesPerPixel, PhotometricInterpretation, Rows, Columns, BitsAllocated, BitsStored,
          HighBit, PixelRepresentation, PlanarConfiguration, PixelSpacing, ImagerPixelSpacing } = imageMetadata;

        const pixelSpacing = PixelSpacing || ImagerPixelSpacing;

        switch (type) {
          case Modules.PIXEL_MODULE:
            return {
              samplesPerPixel: SamplesPerPixel,
              photometricInterpretation: PhotometricInterpretation,
              rows: Rows,
              columns: Columns,
              bitsAllocated: BitsAllocated,
              bitsStored: BitsStored,
              highBit: HighBit,
              pixelRepresentation: PixelRepresentation,
              planarConfiguration: PlanarConfiguration
            };
          case Modules.PLANE_MODULE:
            return {
              rows: Rows,
              columns: Columns,
              pixelSpacing: pixelSpacing ? pixelSpacing.join('/') : '1/1',
              rowPixelSpacing: pixelSpacing ? pixelSpacing[0] : 1,
              columnPixelSpacing: pixelSpacing ? pixelSpacing[1] : 1
            };
          default:
            return undefined;
        }
      });
    }),
    async fetchMetadata () {
      try {
        const images: IImageModel[] = this.get('study/images');
        if (images.length < 1) {
          throw new Error('No images found');
        }
        const metadataPromises: Promise<any>[] = images.map(async (image) => {
          const metadataResponse = await FMSAxios.get(`image/${image.id}/metadata`);
          return dcmjs.data.DicomMetaDictionary.naturalizeDataset(metadataResponse.data);
        });
        const allMetadata = await Promise.all(metadataPromises);

        const payload = allMetadata.reduce((acc, metadataItem, index) => {
          const imageId = createCaseCornerstoneImageId(images[index].id as number);
          return { ...acc, [imageId]: metadataItem };
        }, {});
        commit('caseViewer/metadata/setImagesMetadata', payload);
      } catch (e) {
        console.log(e);
      }
    }
  },
  namespaced: true
};

export default metadata;
