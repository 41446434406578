/* eslint-disable @typescript-eslint/no-explicit-any */
import { jsPDF as JsPDF } from 'jspdf';
function getDataUriFromImage (url: string, maxWidth = 800, quality = 0.7): Promise<string> {
  return new Promise(resolve => {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const scaleFactor = maxWidth / image.naturalWidth;
      canvas.width = maxWidth;
      canvas.height = image.naturalHeight * scaleFactor;

      if (context) context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Use JPEG format to reduce size and adjust quality
      resolve(canvas.toDataURL('image/jpeg', quality));
    };

    image.src = url;
  });
}

function copyComputedStyleToElement (targetElement: HTMLImageElement, sourceElement: HTMLImageElement): HTMLImageElement {
  const sourceElementComputedStyles = getComputedStyle(sourceElement);
  Array.from(sourceElementComputedStyles).forEach(key => {
    targetElement.style.setProperty(key, sourceElementComputedStyles.getPropertyValue(key), sourceElementComputedStyles.getPropertyPriority(key));
  });
  return targetElement;
}

function getClassQuerySelectorString (element: HTMLImageElement): string {
  const classAttribute = element.getAttribute('class');
  if (!classAttribute) throw new Error('Cannot select image during PDF conversion');
  const splitClassNames = classAttribute?.split(' ');

  return splitClassNames.map((name: string) => name ? '.' + name : undefined).join(', ');
}

async function replaceImageSrcWithDataUri (rootElement: Element): Promise<void> {
  const images: HTMLCollectionOf<HTMLImageElement> = rootElement.getElementsByTagName('img');
  for (let image of Array.from(images)) {
    const formattedClassNames = getClassQuerySelectorString(image);
    const sourceImage = document.querySelector<HTMLImageElement>(formattedClassNames);
    if (!sourceImage) throw new Error("Can't find the source element");

    image = copyComputedStyleToElement(image, sourceImage);
    image.src = await getDataUriFromImage(image.src);
  }
}

export default async function generatePDF (elements: HTMLElement[], doc: JsPDF): Promise<JsPDF> {
  const rootHtml = document.createElement('div');

  for (const element of elements) {
    const html = element.cloneNode(true) as HTMLElement;
    html.style.color = 'black';

    await replaceImageSrcWithDataUri(html);

    rootHtml.appendChild(html);
  }

  return doc.html(rootHtml);
}
