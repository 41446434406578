



































import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import Thumbnails from './components/Thumbnails.vue';
import DisplayGrid from './components/DisplayGrid.vue';
import Toolbar from './components/toolbar/Toolbar.vue';
import DisplaysGridTool from './components/DisplaysGridTool.vue';
import { faAngleLeft, faTh } from '@fortawesome/free-solid-svg-icons';
import { IImageModel, IStudieModel } from '@/api/Models';
import { switchContext } from '@/helpers/contextManager';

export default Vue.extend({
  components: {
    Thumbnails,
    DisplayGrid,
    Toolbar,
    DisplaysGridTool
  },
  data () {
    return {
      hideSidebar: false,
      hideDisplaysGridTool: true,
      faAngleLeft,
      faTh
    };
  },
  computed: {
    images: get<IImageModel[]>('study/images'),
    selectedStudy: get<IStudieModel | undefined>('study/selectedStudy')
  },
  created () {
    dispatch('viewer/images/loadImagesIntoEmptyGrid');
  },
  mounted() {
    switchContext('viewer');
  },
  methods: {
    onSidebarToggleClick (): void {
      this.hideSidebar = !this.hideSidebar;
      const viewportGrid = this.$refs['viewport-grid'] as any;
      this.$nextTick(() => viewportGrid.triggerResize());
    },
    onDisplaysGridToolClick (): void {
      this.hideDisplaysGridTool = !this.hideDisplaysGridTool;
    }
  }
});
