
























import Vue from 'vue';
import { get } from 'vuex-pathify';
import { getToolbarTools, IDropdownToolGroup, ITool, getDropdownGroupedTools } from './caseToolLists';

import router from '@/router';
import CaseTool from './CaseTool.vue';
import DropdownToolGroup from './DropdownToolGroup.vue';
import TopBarContainer from '@/components/x-ray/TopBarContainer.vue';
import { IPermissionProfileModel, IRadCaseModel } from '@/api/Models';

export default Vue.extend({
  components: {
    CaseTool,
    TopBarContainer,
    DropdownToolGroup
  },
  computed: {
    profile: get<IPermissionProfileModel>('permissionProfile/profile'),
    isRoot: get<boolean>('permissionProfile/isRoot'),
    selectedCase: get<IRadCaseModel| undefined>('radcase/radcase'),

    tools (): ITool[][] {
      return getToolbarTools(this.selectedCase?.id);
    },
    dropdownGroupedTools (): IDropdownToolGroup[] {
      return getDropdownGroupedTools(this.profile.dicomForms);
    }
  },
  methods: {
    async onBackClick (): Promise<void> {
      await router.go(-1);
    }
  }
});
