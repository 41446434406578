





































































import Vue from 'vue';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import eventBus, { ModalEventNames } from '@/eventBus';
import SelectAllButton from './SelectAllButton.vue';
import ImageSelection from './ImageSelection.vue';
import ModalFrame from './ModalFrame.vue';

export default Vue.extend({
  components: {
    ImageSelection,
    SelectAllButton,
    ModalFrame
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    onSelection: {
      type: Function,
      default: () => undefined
    },
    isConfirmDisabled: {
      type: Boolean,
      default: false
    },
    onConfirm: {
      type: Function,
      required: true
    },
    resetHandler: {
      type: Function,
      required: true
    },
    saveHandler: {
      type: Function,
      required: true
    },
    confirmationButtonText: {
      type: String,
      default: 'Submit'
    },
    filePreviews: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      faTimes,
      localFilePreviews: [] as { name: string; src: string | ArrayBuffer | null; file: Blob; mimeType: string }[],
      allowMulti: true
    };
  },
  watch: {
    filePreviews: {
      immediate: true,
      handler (newVal) {
        this.localFilePreviews = newVal;
      }
    }
  },
  methods: {
    onCloseModalClick (): void {
      eventBus.$emit(ModalEventNames.closeModal);
    },
    onInput (imageIds: number[]) {
      this.$emit('input', imageIds);
    },

    handleFileChange (event: Event) {
      const input = event.target as HTMLInputElement;
      const files = input.files;
      if (!files) return;

      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'image/tiff'];
      const disallowedExtensions = ['exe', 'dll'];
      const maxFileCount = 5; // Limit to 5 files
      const maxFileSize = 100 * 1024 * 1024; // 100 MB in bytes
      let totalSize = 0;

      if (files.length > maxFileCount) {
        Vue.toasted.error(`You can only upload a maximum of ${maxFileCount} files.`);
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Validate file extension
        const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
        if (disallowedExtensions.includes(fileExtension)) {
          Vue.toasted.error(`File type not allowed: ${file.name}`);
          continue;
        }

        // Validate file type
        if (!allowedFileTypes.includes(file.type ?? '')) {
          Vue.toasted.error(`File type not allowed: ${file.name}`);
          continue;
        }

        // Validate total file size
        totalSize += file.size;
        if (totalSize > maxFileSize) {
          Vue.toasted.error('Total file size exceeds 100 MB.');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            const arrayBuffer = e.target.result as ArrayBuffer;
            const base64String = this.arrayBufferToBase64(arrayBuffer);

            this.localFilePreviews.push({
              name: file.name ?? 'unknown-file', // Provide a fallback for file name
              src: `data:${file.type ?? 'application/octet-stream'};base64,${base64String}`, // Provide a fallback for MIME type
              file,
              mimeType: file.type ?? 'application/octet-stream' // Ensure mimeType is always a string
            });

            this.$emit('update:filePreviews', this.localFilePreviews);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    },
    deleteFilePreview (index: number): void {
      this.localFilePreviews.splice(index, 1);
      this.$emit('update:filePreviews', this.localFilePreviews);
    },
    arrayBufferToBase64 (buffer: ArrayBuffer): string {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    isImage (mimeType: string): boolean {
      return mimeType.startsWith('image/');
    }
  }
});
