type EventListener = (event: Event) => void;

// attach an element to the tool overlay
function attachToOverlay (content: string, id: string, overlay: HTMLElement, x: number, y: number, hideable?: boolean): void {
  // if element already exist by this id, update its location and content
  const overlayChildren = Array.from(overlay.children) as HTMLElement[];
  const existingElement = overlayChildren.find(child => child.id === id);
  if (existingElement) {
    existingElement.textContent = content;
    existingElement.style.top = `${y}px`;
    existingElement.style.left = `${x}px`;
  } else {
    // if it doesn't exist, create a new one and append to the tool overlay
    const child = document.createElement('DIV');

    child.classList.add('overlay-child');
    child.setAttribute('id', id);
    child.textContent = content;
    child.style.top = `${y}px`;
    child.style.left = `${x}px`;
    if (hideable === true) child.classList.add('hideable');

    overlay.appendChild(child);
  }
}

// attach an event listener to an element inside an overlay
function attachEventToOverlay (id: string, overlay: HTMLElement, eventType: string, eventHandler: EventListener): void {
  const overlayChildren = Array.from(overlay.children);
  const element = overlayChildren.find(child => child.id === id);

  // Remove previous event listeners to prevent conflicts
  if (element) {
    element.removeEventListener(eventType, eventHandler); // Ensure it's not attached multiple times

    // Add event listener only if the correct context is active
    if (document.body.classList.contains('viewer-active')) {
      element.addEventListener(eventType, eventHandler);
    }
  }
}

// remove all overlay children from overlay
function removeAllFromOverlay (overlay: HTMLElement): void {
  const overlayChildren = Array.from(overlay.children).filter(child => child.classList.contains('overlay-child'));
  overlayChildren.forEach(child => child.remove());
}

// remove an item from the tool overlay by its ID
function removeFromOverlay (id: string, overlay: HTMLElement): void {
  const overlayChildren = Array.from(overlay.children);
  const element = overlayChildren.find(child => child.id === id);
  if (element) {
    element.remove();
  }
}

export {
  attachToOverlay,
  removeFromOverlay,
  attachEventToOverlay,
  removeAllFromOverlay
};
